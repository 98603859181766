import React from 'react'
import PropTypes from 'prop-types'

import { MenuItem } from '@mui/material'
import { LEVEL_NUMBERS, MARCHES_NUMBERS } from '../../../../utils/values'
import Questions from './questionsStep2'

import {
  BoxkTitles,
  TitleInputWithIcon,
  CardIconEtage,
  TextFieldStyled,
  sxInput,
  GridItem,
  GreenSwitch,
  CardIconNbMarche,
  BoxSwitch,
  CardIconAdresse,
  RadioGroupStyle,
  BoxRadio,
  RadioStyle,
  BoxImgCamionVL,
  BoxImgCamionPL,
  BoxStyleRadio,
  CardIconInfoComp,
  CardNote,
  DividerStyle,
  BoxNote,
  IconApartement,
  TitleStyleSpan,
  SousTitleStyleSpan,
} from './style'

const Step2 = ({ order, setOrderChange, loadingActionConfirmer }) => {
  const handleChange = (e) => {
    setOrderChange((state) => ({ ...state, [e.target.name]: e.target.value }))
  }

  const handleSwitcherChange = (e) => {
    setOrderChange((state) => ({ ...state, [e.target.name]: e.target.checked }))
  }

  const handleRadioGroupChange = (e) => {
    setOrderChange((state) => ({
      ...state,
      [e.target.name]: e.target.value == 'true',
    }))
  }

  const handleChangeQuestion = (indexGroupe, indexQuestion, value) => {
    let orderParse = JSON.parse(JSON.stringify(order.questionsRdv))
    orderParse[indexGroupe].questions[indexQuestion].response = value
    setOrderChange((state) => ({ ...state, ['questionsRdv']: orderParse }))
  }

  //CONFIG
  const isFloor = () => order?.config?.floor || order?.codeService == 'En étage'
  const isWithElevator = () => order?.config?.withElevator
  const sizeGrid = () =>
    (isFloor() && !isWithElevator()) || (!isFloor() && isWithElevator()) ? 12 : 6
  const isStairsBeforeElevator = () => order?.config?.stairsBeforeElevator
  const isPlVl = () => order?.config?.plVl
  const isObservation = () => order?.config?.observation

  return (
    <>
      {order?.codeService != null &&
        order?.codeService != '' &&
        order?.codeService != undefined && (
          <GridItem item xs={12} md={12} sm={12}>
            <BoxkTitles>
              <TitleInputWithIcon>
                <IconApartement />
                <TitleStyleSpan component={'span'}>
                  {'Le lieu de livraison est situé:'}
                </TitleStyleSpan>
                <SousTitleStyleSpan component={'span'}>
                  {order.codeService}
                </SousTitleStyleSpan>
              </TitleInputWithIcon>
            </BoxkTitles>
          </GridItem>
        )}
      {isFloor() && (
        <GridItem item xs={12} md={sizeGrid()} sm={sizeGrid()}>
          <BoxkTitles>
            <TitleInputWithIcon>
              <CardIconEtage />
              Etage
            </TitleInputWithIcon>
          </BoxkTitles>
          <TextFieldStyled
            id="etage-Client"
            name="etage"
            onChange={handleChange}
            variant="outlined"
            value={order.etage}
            InputProps={{
              sx: sxInput,
            }}
            select
            SelectProps={{
              MenuProps: {
                style: { height: 300 },
              },
            }}
            disabled={loadingActionConfirmer}
          >
            {LEVEL_NUMBERS.map((h, index) => (
              <MenuItem value={h} key={index}>
                {h}
              </MenuItem>
            ))}
          </TextFieldStyled>
        </GridItem>
      )}
      {isWithElevator() && (
        <GridItem item xs={12} md={sizeGrid()} sm={sizeGrid()}>
          <BoxSwitch>
            <GreenSwitch
              id="avecAscenseur-Client"
              name="avecAscenseur"
              checked={order.avecAscenseur}
              onChange={handleSwitcherChange}
              disabled={loadingActionConfirmer}
            />
            {'Présence Ascenseur'}
          </BoxSwitch>
        </GridItem>
      )}
      {isStairsBeforeElevator() && (
        <GridItem item xs={12} md={12} sm={12}>
          <BoxkTitles>
            <TitleInputWithIcon>
              <CardIconNbMarche />
              {"Nombre des marches avant l'accès à l'ascenseur"}
            </TitleInputWithIcon>
          </BoxkTitles>
          <TextFieldStyled
            id="nbrMarcheAvantAscenseur-Client"
            name="nbrMarcheAvantAscenseur"
            variant="outlined"
            value={order.nbrMarcheAvantAscenseur}
            onChange={handleChange}
            InputProps={{
              sx: sxInput,
            }}
            SelectProps={{
              MenuProps: {
                style: { height: 300 },
              },
            }}
            select
            disabled={loadingActionConfirmer}
          >
            {MARCHES_NUMBERS.map((h, index) => (
              <MenuItem value={h} key={index}>
                {h}
              </MenuItem>
            ))}
          </TextFieldStyled>
        </GridItem>
      )}

      {isPlVl() && (
        <RadioGroupStyle
          id="plVl-Client"
          name="plVl"
          value={order.plVl}
          onChange={handleRadioGroupChange}
        >
          <GridItem item xs={12} md={6} sm={6}>
            <BoxkTitles>
              <TitleInputWithIcon>
                <CardIconAdresse />
                {`Adresse ${
                  order?.courseType == 'livraison' ? "d'arrivée" : 'de départ'
                } est accessible`}
              </TitleInputWithIcon>
            </BoxkTitles>
            <BoxRadio>
              <BoxImgCamionVL />
              <BoxStyleRadio>
                <RadioStyle
                  value={false}
                  id="camionVL-Client"
                  disabled={loadingActionConfirmer}
                />
                Uniquement en VL (Rue étroite, travaux…)
              </BoxStyleRadio>
            </BoxRadio>
          </GridItem>
          <GridItem item xs={12} md={6} sm={6}>
            <BoxRadio ispaddingtop={'true'}>
              <BoxImgCamionPL />
              <BoxStyleRadio>
                <RadioStyle
                  value={true}
                  id="camionPL-Client"
                  disabled={loadingActionConfirmer}
                />
                En poids lourd (Rue à double sens, Rue empruntable par un camion
                benne...)
              </BoxStyleRadio>
            </BoxRadio>
          </GridItem>
        </RadioGroupStyle>
      )}
      {isObservation() && (
        <GridItem item xs={12} md={12} sm={12}>
          <BoxkTitles>
            <TitleInputWithIcon>
              <CardIconInfoComp />
              {'Information complémentaire'}
            </TitleInputWithIcon>
          </BoxkTitles>
          <TextFieldStyled
            id="observation-Client"
            name="observation"
            value={order.observation}
            onChange={handleChange}
            variant="outlined"
            InputProps={{
              sx: sxInput,
            }}
            multiline
            rows={3}
            disabled={loadingActionConfirmer}
          />
        </GridItem>
      )}
      {order?.questionsRdv?.length > 0 && (
        <Questions
          order={order}
          handleChangeQuestion={handleChangeQuestion}
          loadingActionConfirmer={loadingActionConfirmer}
        />
      )}
      {order?.notesRdv?.length > 0 && (
        <CardNote>
          <DividerStyle />
          <BoxNote>{order.notesRdv}</BoxNote>
        </CardNote>
      )}
    </>
  )
}

Step2.propTypes = {
  order: PropTypes.object,
  setOrderChange: PropTypes.func,
  loadingActionConfirmer: PropTypes.bool,
}

export default Step2
